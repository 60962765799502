import React, { ReactElement } from "react";
import {
  BannerWrapper,
  Header,
  ImageBannerContainer,
  ImageOpacityReducer,
  Movetop,
  PageWrapper,
  SectionContainer,
} from "../../components";
import Footer from "../Footer";
import { AboutWrapper } from "./subcomponents";
import { Subtitles, Title, TitleWrapper } from "../../typography";
import { colors, fontfamily } from "../../const/theme";
import seperator_img from "../../assests/separator.png";
import banner_image from "../../assests/banner_img/about_banner.jpg";

const About = (): ReactElement => {
  Movetop();
  return (
    <PageWrapper>
      <Header />
      <BannerWrapper>
        <ImageBannerContainer background={banner_image}>
          <ImageOpacityReducer />
          <TitleWrapper margin="140px 0 0 ">
            <Subtitles
              font="12px"
              fontFamily={fontfamily.fontFamilyDMS}
              letterSpacing="4px"
              fontWeight={700}
              color={colors?.primary}
            >
              FLAVOR FOR ROYALTY
            </Subtitles>{" "}
            <img
              src={seperator_img}
              alt=""
              height={13}
              width={100}
              className="mb-2"
            />
            <Title
              font="75px"
              lineHeight="1"
              color="#fff"
              fontWeight={500}
              fontFamily={fontfamily.fontFamilyForum}
            >
              About us
            </Title>
          </TitleWrapper>
        </ImageBannerContainer>
      </BannerWrapper>
      <AboutWrapper>
        <SectionContainer>
          <TitleWrapper>
            <Subtitles
              font="12px"
              fontFamily={fontfamily.fontFamilyDMS}
              letterSpacing="4px"
              fontWeight={700}
              color={colors?.primary}
            >
              WHO WE ARE
            </Subtitles>{" "}
            <img
              src={seperator_img}
              alt=""
              height={13}
              width={100}
              className="mt-2"
            />
            <Title
              font="35px"
              fontFamily={fontfamily.fontFamilyForum}
              lineHeight="45px"
              fontWeight={500}
              textAlign="center"
              color={colors?.white}
              className="mt-4"
            >
              Namma kitchen Catering is a South Indian catering Service that
              specializes in providing delicious and authentic South Indian
              cuisine for various events and occasions. With a strong emphasis
              on quality and taste, Namma kitchen aims to bring the flavors of
              South India to your home events. The catering service offers a
              wide range of South Indian dishes, including traditional favorites
              like dosas, Idles, vadas, sambar, Rassam, Chicken biryani, Mutton
              Biryani, kothu Parotta and a variety of chutneys veg and non veg
              gravies. These dishes are prepared using fresh ingredients and
              traditional cooking methods, ensuring an authentic and flavorful
              experience.
            </Title>
          </TitleWrapper>
        </SectionContainer>
      </AboutWrapper>
      <Footer />
    </PageWrapper>
  );
};

export default About;
