import { Card, Carousel } from "react-bootstrap";
import styled from "styled-components";
import First_image from "../../assests/section_1/slider-1.jpg";
import { colors } from "../../const/theme";
import card_bg from "../../assests/section_2/pattern-8.svg";

export const FirstSectionWrappper = styled(Carousel)``;

export const CarouselImage = styled(Carousel.Item)`
  background-image: url(${First_image});
  position: relative;
  background-color: #000324;
  background-repeat: no-repeat;
  padding: 200px 92px 150px 92px;
  background-position: center center;
  background-size: cover;
  z-index: 2;
  height: 900px;
`;

//section_2

export const SecondSectionWrapper = styled.section`
  background-color: rgb(14, 13, 12);
  box-shadow: 0 0 0 0 ${colors.white};
  padding: 120px 0;
`;

export const FloatingPng = styled.img`
  position: absolute;
  width: 100px;
`;

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 50px;
  position: relative;
  .para {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    line-height: 30px;
    color: ${colors.white};
  }
  @media (max-width: 500px) {
    justify-content: center; 
  }
`;

export const CardBackgroundSvg = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -70px;
  width: 140px;
  height: 500px;
  top: -30px;
  transition: all 500ms ease;
  background: url(${card_bg}) center repeat;
`;

export const CardContiner = styled(Card)`
  position: relative;
  width: 370px;
  height: 400px;
  background-color: transparent;
  margin: 60px 0;
  cursor: pointer;
  img {
    position: absolute;
    object-fit: contain;
    width: 350px;
  }
  .bordered {
    border: 0.5px solid #a24020;
    position: relative;
    top: 5px;
  }
  &:hover {
    .rotate {
      transform: rotateY(1turn);
    }
  }
  @media (max-width: 500px) {
    margin: 150px 0;
  }
`;

//section_2

export const ThirdSectionWrapper = styled.section`
  background-color: #171819;
  box-shadow: 0 0 0 0 ${colors.white};
  padding: 120px 0;
`;
