import { ReactElement, useState } from "react";
import {
  PageWrapper,
  Header,
  SectionContainer,
  RowWrapper,
  ColumnWrapper,
  PrimaryButton,
  Movetop,
} from "../../components";
import {
  CardWrapper,
  CarouselImage,
  FirstSectionWrappper,
  SecondSectionWrapper,
  ThirdSectionWrapper,
} from "./subComponents";
import { Subtitles, Title, TitleWrapper } from "../../typography";
import seperator_img from "../../assests/separator.png";
import { colors, fontfamily } from "../../const/theme";
import { SectionCardWrapper } from "./helper";
import Footer from "../Footer";
import { useNavigate } from "react-router";
import MENUS_TYPE from "../../const/menus";
import breakfast from "../../assests/section_2/breakfast.jpg";
import desets from "../../assests/section_2/deserts.jpg";
import drinks from "../../assests/section_2/drinks.jpg";
import maincourses from "../../assests/section_2/main-course.jpg";
import snacks from "../../assests/section_2/snacks.jpg";
import about from "../../assests/section_3/food-cutlery.jpg";

const Home = (): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [position, setPosition] = useState(0);
  const onScroll = (event: any) => {
    const scrollPosition = event.target.scrollingElement.scrollTop;
    setPosition(scrollPosition);
  };
  document.addEventListener("scroll", onScroll);

  const navigate = useNavigate();
  Movetop();
  return (
    <PageWrapper>
      <Header />
      <FirstSectionWrappper indicators={false} controls={true} fade={true}>
        <CarouselImage interval={2000}>
          <TitleWrapper margin="90px 0 0 0 ">
            <Subtitles
              font="12px"
              fontFamily={fontfamily.fontFamilyDMS}
              letterSpacing="4px"
              fontWeight={700}
              color={colors.primary}
            >
              AMAZING & DELICIOUS
            </Subtitles>
            <img
              src={seperator_img}
              alt=""
              height={13}
              width={100}
              className="mt-2"
            />
            <Title
              font="80px"
              fontFamily={fontfamily.fontFamilyForum}
              lineHeight="80px"
              fontWeight={400}
              color={colors?.white}
              className="mt-2"
            >
              For the love of <br />
              delicious food
            </Title>{" "}
            <Subtitles
              font="20px"
              fontFamily={fontfamily.fontFamilyDMS}
              lineHeight="80px"
              fontWeight={400}
              color={colors?.white}
            >
              Come with family & feel the joy of mouthwatering food
            </Subtitles>
          </TitleWrapper>
        </CarouselImage>
        <CarouselImage interval={2000}>
          <TitleWrapper margin="90px 0 0 0 ">
            <Subtitles
              font="12px"
              fontFamily={fontfamily.fontFamilyDMS}
              letterSpacing="4px"
              fontWeight={700}
              color={colors?.primary}
            >
              TRADATIONAL & HYGINE
            </Subtitles>
            <img
              src={seperator_img}
              alt=""
              height={13}
              width={100}
              className="mt-2"
            />
            <Title
              font="80px"
              fontFamily={fontfamily.fontFamilyForum}
              lineHeight="80px"
              fontWeight={400}
              color={colors?.white}
              className="mt-2"
            >
              Where every meal <br />
              is an adventure.
            </Title>
            <Subtitles
              font="20px"
              fontFamily={fontfamily.fontFamilyDMS}
              lineHeight="80px"
              fontWeight={400}
              color={colors?.white}
            >
              Come with family & feel the joy of mouthwatering food
            </Subtitles>
          </TitleWrapper>
        </CarouselImage>{" "}
        <CarouselImage interval={2000}>
          <TitleWrapper margin="90px 0 0 0 ">
            <Subtitles
              font="12px"
              fontFamily={fontfamily.fontFamilyDMS}
              letterSpacing="4px"
              fontWeight={700}
              color={colors?.primary}
            >
              DELIGHTFUL EXPERIENCE
            </Subtitles>
            <img
              src={seperator_img}
              alt=""
              height={13}
              width={100}
              className="mt-2"
            />
            <Title
              font="80px"
              fontFamily={fontfamily.fontFamilyForum}
              lineHeight="80px"
              fontWeight={400}
              color={colors?.white}
              className="mt-2"
            >
              Crafted with passion, <br />
              served with love.
            </Title>
            <Subtitles
              font="20px"
              fontFamily={fontfamily.fontFamilyDMS}
              lineHeight="80px"
              fontWeight={400}
              color={colors?.white}
            >
              Come with family & feel the joy of mouthwatering food
            </Subtitles>
          </TitleWrapper>
        </CarouselImage>
      </FirstSectionWrappper>
      <SecondSectionWrapper>
        <SectionContainer>
          <TitleWrapper>
            <Subtitles
              font="12px"
              fontFamily={fontfamily.fontFamilyDMS}
              letterSpacing="4px"
              fontWeight={700}
              color={colors?.primary}
            >
              FLAVOR FOR ROYALTY
            </Subtitles>{" "}
            <img
              src={seperator_img}
              alt=""
              height={13}
              width={100}
              className="mt-2"
            />
            <Title
              font="60px"
              fontFamily={fontfamily.fontFamilyForum}
              lineHeight="1.20em"
              fontWeight={400}
              color={colors?.white}
              className="mt-4"
            >
              We Offer Top Notch
            </Title>
          </TitleWrapper>
          <CardWrapper>
            <SectionCardWrapper
              title="Breakfast"
              image={breakfast}
              onClick={() => {
                navigate("/menu-lists/breakfast", {
                  state: { menu_name: MENUS_TYPE.breakFast },
                });
              }}
            />

            <SectionCardWrapper
              image={maincourses}
              title="Main Courses"
              onClick={() => {
                navigate("/menu-lists/maincourse", {
                  state: { menu_name: MENUS_TYPE.mainCourse },
                });
              }}
            />

            <SectionCardWrapper
              image={snacks}
              title="Snacks/Appetizer"
              onClick={() => {
                navigate("/menu-lists/snacks", {
                  state: { menu_name: MENUS_TYPE.snacks },
                });
              }}
            />
          </CardWrapper>
          <CardWrapper style={{ marginTop: "70px" }}>
            <SectionCardWrapper
              title="Desserts"
              image={desets}
              onClick={() => {
                navigate("/menu-lists/desserts", {
                  state: { menu_name: MENUS_TYPE.desserst },
                });
              }}
            />
            <SectionCardWrapper
              title="Drinks"
              image={drinks}
              onClick={() => {
                navigate("/menu-lists/drinks", {
                  state: { menu_name: MENUS_TYPE.drinks },
                });
              }}
            />{" "}
            <div style={{ width: "370px" }}></div>
          </CardWrapper>
        </SectionContainer>
      </SecondSectionWrapper>
      <ThirdSectionWrapper>
        <SectionContainer>
          <RowWrapper>
            <ColumnWrapper>
              <TitleWrapper>
                <Subtitles
                  font="12px"
                  fontFamily={fontfamily.fontFamilyDMS}
                  letterSpacing="4px"
                  fontWeight={700}
                  color={colors?.primary}
                >
                  OUR STORY
                </Subtitles>{" "}
                <img
                  src={seperator_img}
                  alt=""
                  height={13}
                  width={100}
                  className="mt-2"
                />
                <Title
                  font="60px"
                  fontFamily={fontfamily.fontFamilyForum}
                  lineHeight="1.20em"
                  fontWeight={400}
                  color={colors?.white}
                  className="mt-4"
                >
                  Every Flavor <br /> Tells a Story
                </Title>
                <Subtitles
                  font="16px"
                  fontFamily={fontfamily.fontFamilyDMS}
                  lineHeight="30px"
                  fontWeight={400}
                  textAlign="center"
                  color={colors?.white}
                  className="mt-2 w-50"
                >
                  Namma kitchen Catering is a South Indian catering Service that
                  specializes in providing delicious and authentic South Indian
                  cuisine for various events and occasions. With a strong
                  emphasis on quality and taste, Namma kitchen aims to bring the
                  flavors of South India to your home events.
                </Subtitles>
                <PrimaryButton
                  text="Read More"
                  onClick={() => {
                    navigate("/about-us");
                  }}
                />
              </TitleWrapper>
            </ColumnWrapper>
            <ColumnWrapper>
              <img src={about} alt="" style={{ borderRadius: "5px" }} />
            </ColumnWrapper>
          </RowWrapper>
        </SectionContainer>
      </ThirdSectionWrapper>
      <Footer />
    </PageWrapper>
  );
};

export default Home;
