import { ReactElement } from "react";
import {
  CardBackgroundSvg,
  FooterAddressWrapper,
  FooterCardContainer,
  FooterCardWrapper,
  FooterWrapper,
} from "./subcomponents";
import { ImageOpacityReducer, SectionContainer } from "../../components";
import { Anchortext, LogoWrapper } from "../../components/Header/subComponents";
import brand_image from "../../assests/brand.png";
import { Title, TitleWrapper } from "../../typography";
import { fontfamily } from "../../const/theme";
import { useNavigate } from "react-router";

const Footer = (): ReactElement => {
  const navigate = useNavigate();
  return (
    <FooterWrapper>
      <SectionContainer>
        <ImageOpacityReducer />
        <FooterCardWrapper>
          <FooterCardContainer>
            {" "}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <span>
                <Anchortext
                  color="rgb(167,167,167)"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  HOME
                </Anchortext>
              </span>{" "}
              <span className="mt-3">
                <Anchortext
                  color="rgb(167,167,167)"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  MENU
                </Anchortext>
              </span>{" "}
              <span className="mt-3">
                <Anchortext
                  color="rgb(167,167,167)"
                  onClick={() => {
                    navigate("/about-us");
                  }}
                >
                  ABOUT US
                </Anchortext>
              </span>{" "}
              <span className="mt-3">
                <Anchortext
                  color="rgb(167,167,167)"
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                >
                  CONTACT
                </Anchortext>
              </span>
            </div>
          </FooterCardContainer>{" "}
          <FooterCardContainer width="600px">
            <CardBackgroundSvg />
            <ImageOpacityReducer />
            <FooterAddressWrapper>
              <TitleWrapper>
                <LogoWrapper>
                  <img src={brand_image} alt="" />
                </LogoWrapper>
                <Title
                  font="16px"
                  fontFamily={fontfamily.fontFamilyDMS}
                  letterSpacing="4px"
                  lineHeight="30px"
                  fontWeight={400}
                  color={"rgb(167, 167, 167)"}
                  className="mt-4"
                >
                  760 Panorama Ct, Aurora, IL -60502, US <br />
                  Info@nammakitchen.us
                  <br /> Booking Request : +88-123-123456
                  <br /> Open : 09:00 am - 01:00 pm
                </Title>
              </TitleWrapper>
            </FooterAddressWrapper>
          </FooterCardContainer>{" "}
          <FooterCardContainer>
            {" "}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <span>
                <Anchortext color="rgb(167,167,167)">FACEBOOK</Anchortext>
              </span>{" "}
              <span className="mt-3">
                <Anchortext
                  color="rgb(167,167,167)"
                  onClick={() => {
                    window.open("https://www.instagram.com/nammakitch");
                  }}
                >
                  INSTAGRAM
                </Anchortext>
              </span>{" "}
              <span className="mt-3">
                <Anchortext color="rgb(167,167,167)">TWITTER</Anchortext>
              </span>{" "}
              <span className="mt-3">
                <Anchortext
                  color="rgb(167,167,167)"
                  onClick={() => {
                    window.open("https://maps.app.goo.gl/danHuwPVLuC5GhCSA");
                  }}
                >
                  GOOGLE MAP
                </Anchortext>
              </span>
            </div>
          </FooterCardContainer>
        </FooterCardWrapper>
      </SectionContainer>
      <div className="footer_text">
        © 2024 Restaurt. All Rights Reserved | Dhileeps Infosolution
      </div>
    </FooterWrapper>
  );
};

export default Footer;
