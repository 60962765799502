import styled from "styled-components";
import { HeaderProsp } from "./types";
import { colors } from "../../const/theme";

export const HeaderWrapper = styled.header`
  display: flex;
  transition: 0.6s;
  @media (max-width: 950px) {
    display: none;
  }
`;

export const ContatcDetialsWrapper = styled.div`
  background: transparent;
  width: 100%;
  position: absolute;
  height: 50px;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid ${colors.white};
  padding-left: 18px;
  padding-right: 18px;
  z-index: 111;
  line-height: 30px;
  font-size: 14px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  i {
    position: relative;
    top: 2px;
    right: 6px;
    font-size: 15px;
  }
  span {
    color: ${colors.white};
    display: flex;
    position: relative;
    padding-top: 10px;
  }
  p {
    &:nth-child(2) {
      line-height: 30px;
      font-size: 14px;
      font-weight: 400;
      font-family: "DM Sans", sans-serif;
      padding-left: 30px;
    }
  }
`;

export const Navbar = styled.div<HeaderProsp>`
  background: ${({ background }) => (background ? background : "transparent")};
  height: ${({ height }) => (height ? height : "100px")};
  position: ${({ position }) => (position ? position : "absolute")};
  box-shadow: ${({ scrollPosition }) =>
    scrollPosition ? `0px 10px 60px 0px RGBA(255, 255, 255, 0.07)` : null};
  left: 0;
  top: 50px;
  width: 100%;
  z-index: 111;
  padding: 0;
  transition: 0.6s;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  padding-left: 18px;
  padding-right: 18px;
  transition: all 0.3s;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  img {
    width: 130px;
    height: 130px;
    object-fit: contain;
  }
`;

export const CenterMenu = styled.div`
  position: relative;
  //right: 25px;
`;

export const OrderList = styled.ul`
  li {
    display: inline-block;
    position: relative;
    list-style: none;
    margin: 0 50px;
    color: ${colors.white};
  }
`;

export const Anchortext = styled.p<HeaderProsp>`
  font-size: 12px;
  display: inline-block;
  align-items: center;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  font-family: "DM Sans", sans-serif;
  position: relative;
  transition: 0.6s;
  cursor: pointer;
  color: ${({ color }) => (color ? color : "rgb(255,255,255)")};
  &:hover {
    color: ${colors.secondary};
    transition: all 500ms ease;
  }
  &&:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    top: 20px;
    position: absolute;
    /* background: #e4c290; */
    background-color: ${colors.secondary};
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  &:hover:after {
    width: 100%;
    left: 0;
  }
`;

export const RightMenu = styled.div<HeaderProsp>`
  color: ${({ color }) => (color ? color : colors.white)};
  font-size: 18px;
  cursor: pointer;
  .user_icon {
    transition: all 500ms ease;
  }
  &:hover {
    transition: all 500ms ease;
    .user_icon {
      color: ${colors.primary};
    }
  }
`;

export const Dropdown = styled.div`
  span {
    position: absolute;
    text-decoration: none;
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 200px;
    justify-content: start;
    align-items: start;
    background: rgb(14, 13, 11);
    border: none;
    border-radius: 5px;
    padding: 30px 0;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.03),
      -2px 0px 10px 1px rgba(0, 0, 0, 0.03);
  }
  p {
    position: relative;
    display: block;
    padding: 0;
    padding: 10px 35px;
    font-size: 15px;
    line-height: 30px;
    font-weight: 400;
    color: ${colors.white};
    cursor: pointer;
    text-transform: capitalize;
    transition: all 300ms ease;
    text-decoration: none;
    font-family: "DM Sans", sans-serif;
    &:hover {
      color: ${colors.secondary};
    }
  }
`;

//Mobile

export const MobileHeader = styled.header`
  display: none;
  width: 100%;
  @media (max-width: 950px) {
    display: inline-block;
    background: rgba(0, 0, 0);
  }
`;

export const MobileNavHeader = styled.div`
  padding: 10px 18px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;

  i {
    font-size: 50px;
    color: #fff;
  }
`;

export const MobileNavMenu = styled.div`
  background: black;
  font-family: "DM Sans", sans-serif;
  height: 150%;
  .close {
    color: #fff;
    position: relative;
    font-size: 30px;
    left: 80%;
    top: 10px;
  }
  .wrapper {
    padding: 50px;
  }

  .menu,
  .child_menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  p {
    font-size: 12px;
    display: inline-block;
    align-items: center;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    padding: 20px;
    font-family: "DM Sans", sans-serif;
    position: relative;
    transition: 0.6s;
    cursor: pointer;
    color: ${({ color }) => (color ? color : "rgb(255,255,255)")};
    &:hover {
      color: ${colors.secondary};
      transition: all 500ms ease;
    }
    &&:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 50%;
      top: 20px;
      position: absolute;
      /* background: #e4c290; */
      background-color: ${colors.secondary};
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    &:hover:after {
      width: 100%;
      left: 0;
    }
  }
  .child_menu {
    width: 100%;
    background-color: #464646;
    border-radius: 5px;
  }
`;
