import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

interface DrawerProps {
  show: boolean;
  onClose: () => void;
  onOpen: () => void;
  component: React.ReactNode;
}

const Drawer = ({ show, onClose, onOpen, component }: DrawerProps) => {
  return (
    <div>
      <SwipeableDrawer
        anchor={"left"}
        open={show}
        onClose={onClose}
        onOpen={onOpen}
      >
        {component}
      </SwipeableDrawer>
    </div>
  );
};

export default Drawer;
