import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Brand from "../../assests/brand.png";
import { LoaderWrapper } from "./styledcomponents";

const PathChangeLoader = ({ children }: any) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  return <>{loading ? <Loader /> : children}</>;
};

const Loader = () => {
  return (
    <LoaderWrapper>
      <img src={Brand} alt="" width={300} /> 
    </LoaderWrapper>
  );
};

export default PathChangeLoader;
