import styled from "styled-components";

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #171819;
  img {
    animation: fadeIn 1s ease-in-out infinite alternate;
  }

  @keyframes fadeIn {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }
`;
