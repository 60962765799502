import { CardContiner, CardBackgroundSvg } from "./subComponents"; 
import { Subtitles, TitleWrapper } from "../../typography";
import { fontfamily, colors } from "../../const/theme";

interface SectionCardWrapperProps {
  title: string;
  onClick: () => void;
  image?:any
}

export const SectionCardWrapper = ({
  title,
  onClick,
  image,
}: SectionCardWrapperProps) => {
  return (
    <CardContiner onClick={onClick}>
      <CardBackgroundSvg className="rotate" />
      <img src={image} alt="" />
      <TitleWrapper margin="490px 0 0 ">
        <Subtitles
          font="35px"
          fontFamily={fontfamily.fontFamilyForum}
          lineHeight="1.20em"
          fontWeight={400}
          color={colors?.white}
        >
          {title}
        </Subtitles>
        <Subtitles
          font="12px"
          fontFamily={fontfamily.fontFamilyForum}
          lineHeight="1.20em"
          fontWeight={400}
          letterSpacing="0.2em"
          color={colors?.primary}
        >
          VIEW MENU
          <p className="bordered"></p>
        </Subtitles>
      </TitleWrapper>
    </CardContiner>
  );
};
