import MENUS_TYPE from "../../const/menus";
import { breakFast, desserts, drinks, mainCourse, snacks } from "./const";
import { CateringMenuProps, MenuList } from "./types";

const MenusCourses = ({ menu_name }: CateringMenuProps): Array<MenuList> => {
  let menus_lists: Array<MenuList>;
  switch (menu_name) {
    case MENUS_TYPE.breakFast:
      menus_lists = breakFast;
      break;
    case MENUS_TYPE.mainCourse:
      menus_lists = mainCourse;
      break; 
    case MENUS_TYPE.snacks:
      menus_lists = snacks;
      break;
    case MENUS_TYPE.desserst:
      menus_lists = desserts;
      break;
    case MENUS_TYPE.drinks:
      menus_lists = drinks;
      break;
    default:
      menus_lists = [];
  } 
  return menus_lists;
};

export default MenusCourses;
